.common_dialog_box[data-v-327dba11] {
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title[data-v-327dba11] {
  font-size: 14px;
  color: black;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span[data-v-327dba11] {
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .item[data-v-327dba11]:nth-child(2n+1) {
  margin-left: 0;
}
.new_dialog .el-button--primary[data-v-327dba11] {
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}
.common_dialog_box .el-select[data-v-327dba11] {
  margin: 0 0 0 0 !important;
  width: 100%;
}
.common_dialog_box[data-v-327dba11] .el-input__wrapper {
  width: 200px;
}
.main[data-v-327dba11] {
  display: flex;
}
.main p[data-v-327dba11] {
  margin-bottom: 10px;
}