.non_padding[data-v-4b1db528] {
  padding: 0;
}
.non_padding .cell[data-v-4b1db528] {
  padding: 0;
}
.top_select[data-v-4b1db528] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
span[data-v-4b1db528] {
  font-size: 12px;
}
span.normal.parent[data-v-4b1db528] {
  font-weight: 600;
}
span.normal.total[data-v-4b1db528] {
  font-weight: 600;
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
span.normal.child[data-v-4b1db528] {
  padding-left: 10px;
}
span.table-cell-span.parent[data-v-4b1db528] {
  font-weight: 600;
  text-underline-offset: 3px;
}
span.table-cell-span.total[data-v-4b1db528] {
  font-weight: 600;
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
span.table-cell-span.normal[data-v-4b1db528] {
  text-underline-offset: 3px;
  padding-left: 10px;
}